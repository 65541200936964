import React, { ChangeEvent, useState } from "react";
import cn from "classnames";
import { createStyles, makeStyles } from "@mui/styles";
import { Box, Theme, ClickAwayListener } from "@mui/material";
import { SearchIconSvg } from "../../assets/icons/SearchIconSvg";

interface searchProps {
  className?: string;
  placeholder?: string;
  value?: string;
  type?: string;
  name?: string;
  icon?: any;
  style?: any;
  searchFormStyles?: React.CSSProperties;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  iconSize?: any;
  floatingSearchBar?: boolean;
  iconStyles?: any;
}

const Search: React.FC<searchProps> = (props) => {
  const styles = useStyles();
  const [activeSearchDialog, setActiveSearchDialog] = useState(false);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    props.onChange(event);
  };
  return (
    <>
      {props?.floatingSearchBar ? (
        <ClickAwayListener onClickAway={() => setActiveSearchDialog(false)}>
          <Box
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "24px",
              height: "52px",
              borderRadius: "40px",
              bgcolor: "#141414",
            }}
          >
            <button
              onClick={() => {
                setActiveSearchDialog(true);
              }}
              className={styles.result}
            >
              <SearchIconSvg width="24px" height="24px" color="#000000" />
            </button>
            {activeSearchDialog && (
              <Box
                sx={{
                  ...props?.searchFormStyles,
                  position: "absolute",
                  zIndex: 300,
                  transition: "2s ease-in-out",
                  top: "-30px",
                  right: "40px",
                }}
                className={cn(props.className, styles.form)}
              >
                <input
                  style={props.style}
                  className={styles.input}
                  type={props.type}
                  value={props.value}
                  onChange={handleInputChange}
                  name={props.name}
                  autoComplete="off"
                  placeholder={props.placeholder}
                  required
                />
                <button className={styles.result}>
                  <SearchIconSvg color="#000000" />
                </button>
              </Box>
            )}
          </Box>
        </ClickAwayListener>
      ) : (
        <Box
          sx={{
            ...props?.searchFormStyles,
          }}
          className={cn(props.className, styles.form)}
        >
          <input
            style={props.style}
            className={styles.input}
            type={props.type}
            value={props.value}
            onChange={handleInputChange}
            name={props.name}
            autoComplete="off"
            placeholder={props.placeholder}
            required
          />
          {props?.icon ? (
            <button style={{ ...props?.iconStyles }} className={styles.result}>
              <SearchIconSvg width="18px" height="18px" color="#FFFFFF" />
            </button>
          ) : (
            <></>
          )}
        </Box>
      )}
    </>
  );
};

export default Search;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      position: "relative",
      width: "100%",
      minWidth: "360px",
      display: "flex",
      backgroundColor: "#141414",
      borderRadius: "14px",
      alignItems: "center",
      "@media (max-width: 600px)": {
        minWidth: "auto",
      },
    },
    input: {
      width: "100%",
      padding: "0px 20px 0px 15px",
      borderRadius: "14px",
      backgroundColor: "#141414",
      boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
      border: "none",
      fontFamily: "Inter Tight",
      outline: "none",
      fontWeight: "400",
      fontSize: "18px",
      "&::placeholder": {
        color: "rgba(255, 255, 255, 0.5)",
        fontWeight: "400",
      },
    },
    result: {
      position: "absolute",
      right: 16,
      width: "18px",
      height: "18px",
      border: "none",
      backgroundColor: "transparent",
      "& svg": {
        fill: "$n4",
        transition: "fill .2s",
      },
      "&:hover svg": {
        fill: "$p1",
      },
    },
  })
);

import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import ProductsList from "./product/ProductsList";
import { SECTION_MIN_HEIGHT } from "../../utils/constants";
import ProductTypeDialog from "./createProduct/ProductTypeDialog";
import AddProduct from "./createProduct/AddProduct";
import AddComponent from "./createProduct/AddComponent";
import EditComponent from "./product/components/EditComponents";

type Props = {};

const Products = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeSelectTypeDialog, setActiveSelectTypeDialog] = useState(false);

  const handleCloseSelectTypeDialog = (type?: any) => {
    if (type) {
      if (type === "PRODUCT") {
        navigate("/product/product/create");
      }
      if (type === "COMPONENT") {
        navigate("/product/component/create");
      }
    }
    setActiveSelectTypeDialog(false);
  };

  useEffect(() => {
    if (window.location.pathname?.includes("/product/create")) {
      setActiveSelectTypeDialog(true);
    }
  }, [location.pathname]);
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: SECTION_MIN_HEIGHT,
      }}
    >
      {activeSelectTypeDialog && (
        <ProductTypeDialog
          activeDialog={activeSelectTypeDialog}
          handleDialog={handleCloseSelectTypeDialog}
        />
      )}

      <Routes>
        <Route path="/" element={<ProductsList />} />
        <Route path="/create" element={<AddProduct />} />
        <Route path="/component/multiple-edit" element={<EditComponent />} />
        <Route path="/component/create" element={<AddComponent />} />
        <Route path="/component/edit/:id" element={<AddComponent />} />
      </Routes>
    </Box>
  );
};

export default Products;

import { Box } from "@mui/material";
import React, { ReactNode } from "react";
import { SECTION_MIN_HEIGHT } from "../utils/constants";

interface pageProps {
  children: ReactNode;
}

const Page: React.FC<pageProps> = (props) => {
  return (
    <Box
      className="custom-scrollbar"
      sx={{
        width: "100%",
        maxHeight: SECTION_MIN_HEIGHT,
        minHeight: SECTION_MIN_HEIGHT,
        minWidth: "100%",
        height: "100%",
        backgroundColor: "transparent !important",
        overflowY: "scroll",
      }}
    >
      {props.children}
    </Box>
  );
};

export default Page;

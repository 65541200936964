import React, { createContext, useContext, useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
} from "react-router-dom";
import Dashboard from "./dashboard/Dashboard";
import { ThemeProvider } from "@mui/material/styles";
import { darkTheme, lightTheme } from "./utils/theme";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import Protected from "./routes/Protected";
import Login from "./login/Login";
import { ToastContainer } from "react-toastify";

interface GlobalContextType {
  setActiveNavigation: any;
  activeNavigation: any;
  navigations: any;
  setNavigations: any;
  breadcrumbs: any;
  setBreadcrumbs: any;
  breadCrumbsLoading: any;
  setBreadCrumbsLoading: any;
  handleBreadCrumbsLoading: any;
  componentDetailsList: any;
  setComponentDetailsList: any;
  formComplete: boolean;
  setFormComplete: any;
  activeSaveAsDraftDialog: boolean;
  setActiveSaveAsDraftDialog: any;
  userDetails: any;
  setUserDetails: any;
  showSelectedRowsMenuFooter: any;
  setShowSelectedRowsMenuFooter: any;
}
export const GlobalContext = createContext<GlobalContextType | undefined>(
  undefined
);
export const useGlobalContext = (): any => useContext(GlobalContext)!;

function App() {
  const [currentTheme] = useState<"light" | "dark">("dark");
  const theme = currentTheme === "light" ? lightTheme : darkTheme;

  const [breadCrumbsLoading, setBreadCrumbsLoading] = useState<boolean>(false);
  const [breadcrumbs, setBreadcrumbs] = useState<any>([
    <NavLink to="/">Home</NavLink>,
  ]);

  const [showSelectedRowsMenuFooter, setShowSelectedRowsMenuFooter] =
    useState<boolean>(false);

  // Loged in user details handling state
  const [userDetails, setUserDetails] = useState("");

  // All/Active navigation handling states
  const [navigations, setNavigations] = useState<any>([]);
  const [activeNavigation, setActiveNavigation] = useState<any>(() => {
    try {
      const storedValue = window.localStorage.getItem("ACTIVE_NAVIGATION");
      return storedValue ? JSON.parse(storedValue) : navigations;
    } catch (error) {
      console.error("Error parsing JSON from localStorage:", error);
      return navigations;
    }
  });

  // components in product form handling states
  const [componentDetailsList, setComponentDetailsList] =
    useStateWithCallbackLazy([]);
  const [formComplete, setFormComplete] = useState<boolean>(true);
  const [activeSaveAsDraftDialog, setActiveSaveAsDraftDialog] =
    useState<boolean>(false);

  // Breadcrumbs loading handling function
  const handleBreadCrumbsLoading: any = (loading: boolean) => {
    setBreadCrumbsLoading(loading);
  };

  const globalContextProps = {
    setActiveNavigation,
    activeNavigation,
    navigations,
    setNavigations,
    breadCrumbsLoading,
    setBreadCrumbsLoading,
    breadcrumbs,
    setBreadcrumbs,
    handleBreadCrumbsLoading,
    componentDetailsList,
    setComponentDetailsList,
    formComplete,
    setFormComplete,
    activeSaveAsDraftDialog,
    setActiveSaveAsDraftDialog,
    userDetails,
    setUserDetails,
    showSelectedRowsMenuFooter,
    setShowSelectedRowsMenuFooter,
  };

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <GlobalContext.Provider value={globalContextProps}>
          <ToastContainer />
          <Router>
            <Routes>
              <Route element={<Protected />}>
                <Route path="/*" element={<Dashboard />} />
              </Route>
              <Route path="/login" element={<Login />} />
            </Routes>
          </Router>
        </GlobalContext.Provider>
      </ThemeProvider>
    </div>
  );
}

export default App;

import { Box, TableCell, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { statusType } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import { monthStringFormatDate } from "../../../utils/extensions";

interface tableRowProps {
  row: any;
  handleRefreshData: any;
}

const Row: React.FC<tableRowProps> = ({ row }) => {
  const navigate = useNavigate();

  const [activeFeedbacksCount, setActiveFeedbacksCount] = useState<number>();

  useEffect(() => {
    const activeFeedbacks = row?.feedbacks.filter(
      (feedback: any) =>
        feedback.status?.toUpperCase() === "OPEN" ||
        feedback.status?.toUpperCase() === "IN PROGRESS"
    );
    setActiveFeedbacksCount(activeFeedbacks?.length);
  }, []);
  return (
    <TableRow
      onClick={() => {
        navigate(`/feedbacks/${row?.id}`, {
          state: {
            component: row,
          },
        });
      }}
      sx={{
        cursor: "pointer",
        ":hover": {
          bgcolor: "rgba(255, 255, 255, 0.06) !important",
        },
      }}
      hover
      role="checkbox"
      tabIndex={-1}
      key={row?.id}
    >
      <TableCell
        sx={{
          minWidth: "200px",
          maxWidth: "200px",
          width: "200px",
          borderBottom: "1px solid rgba(255, 255, 255, 0.06)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
          }}
        >
          {row?.documents?.length > 0 && (
            <Box
              sx={{
                display: "flex",
                aspectRatio: "104/72",
                justifyContent: "center",
                alignItems: "center",
                width: "104px",
                height: "72px",
                borderRadius: "3px",
              }}
            >
              <img
                style={{
                  width: "104px",
                  height: "72px",
                  borderRadius: "3px",
                }}
                alt="cover"
                src={row?.documents[0]?.previewURL}
              />
            </Box>
          )}
          <Typography
            sx={{ color: "white", fontWeight: "300" }}
            variant="body2"
          >
            {row?.title || "-"}
          </Typography>
        </Box>
      </TableCell>

      <TableCell
        sx={{
          width: "250px",
          minWidth: "250px",
          maxWidth: "250px",
          borderBottom: "1px solid rgba(255, 255, 255, 0.06)",
        }}
      >
        <Box
          sx={{ display: "flex", gap: "10px", width: "100%", flexWrap: "wrap" }}
        >
          <Typography
            sx={{
              color: "white",
              fontWeight: "300",
              textAlign: "center",
              width: "100%",
            }}
            variant="body2"
          >
            {row?.recentFeedbackDate
              ? monthStringFormatDate(row?.recentFeedbackDate)
              : "-"}
          </Typography>
        </Box>
      </TableCell>

      <TableCell
        sx={{
          width: "250px",
          minWidth: "250px",
          maxWidth: "250px",
          borderBottom: "1px solid rgba(255, 255, 255, 0.06)",
        }}
      >
        <Box
          sx={{ display: "flex", gap: "10px", width: "100%", flexWrap: "wrap" }}
        >
          <Typography
            sx={{
              color: "white",
              fontWeight: "300",
              textAlign: "center",
              width: "100%",
            }}
            variant="body2"
          >
            {activeFeedbacksCount || 0}
          </Typography>
        </Box>
      </TableCell>

      <TableCell
        sx={{
          width: "80px",
          minWidth: "80px",
          maxWidth: "80px",
          borderBottom: "1px solid rgba(255, 255, 255, 0.06)",
        }}
      >
        <Typography sx={{ color: "white", fontWeight: "300" }} variant="body2">
          {row?.status ? statusType(row?.status) : "-"}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default Row;

import React, { CSSProperties } from "react";
import { styled } from "@mui/material/styles";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";

interface CustomCheckBoxInput extends CheckboxProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>, id: any) => void;
  id?: string;
  style?: CSSProperties;
  value?: boolean;
  checkboxColor?: string;
  checkboxBorder?: string;
}

const CheckboxInput = (checkbox: CustomCheckBoxInput) => {
  const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: "6px",
    width: "20px",
    height: "20px",
    border: "1px solid rgba(255, 255, 255, 0.06)",
    backgroundColor:
      theme.palette.mode === "dark"
        ? checkbox?.checkboxColor || "rgba(255, 255, 255, 0.06)"
        : "#f5f8fa",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ".Mui-focusVisible &": {
      // outline: "2px auto rgba(19,124,189,.6)",
      // outlineOffset: 2,
    },
    // "input:hover ~ &": {
    //   backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
    // },
    "input:disabled ~ &": {
      boxShadow: "none",
    },
    "input:checked ~ &": {
      border: checkbox?.checkboxBorder || "1px solid rgba(255, 255, 255, 0.06)",
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: checkbox?.checkboxColor || "rgba(255, 255, 255, 0.06)",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "20px",
      height: "20px",
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    // "input:hover ~ &": {
    //   backgroundColor: "#106ba3",
    // },
  });

  function BpCheckbox(props: any) {
    return (
      <Checkbox
        id={checkbox.id}
        onChange={(e) => {
          checkbox.onChange(e, checkbox.id);
        }}
        checked={checkbox.value}
        sx={{
          "&:hover": { bgcolor: "rgba(255, 255, 255, 0.06)" },
          padding: "0px",
          borderRadius: "6px",
          ...checkbox.style,
        }}
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        inputProps={{ "aria-label": "Checkbox demo" }}
        {...props}
      />
    );
  }
  return <BpCheckbox />;
};

export default CheckboxInput;

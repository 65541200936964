import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { PlusIconSvg } from "../../assets/icons/PlusIconSvg";

interface SubItemListProps {
  open?: boolean;
  helpCenterBadgeText?: string;
  setActiveNavigation: any;
  activeNavigation: any;
}

const BottomListItems: React.FC<SubItemListProps> = ({
  open,
  helpCenterBadgeText,
  setActiveNavigation,
  activeNavigation,
}) => {
  const navigate = useNavigate();

  return (
    <List
      sx={{
        paddingX: open ? "0px" : "0px",
        paddingBottom: "24px",
        paddingTop: "24px",
      }}
    >
      <ListItem
        onClick={() => {
          navigate("/product/create");
        }}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0px",
          width: "100%",
        }}
      >
        <ListItemButton
          disableRipple
          sx={{
            minHeight: open ? "52px" : "40px",
            width: "100%",
            justifyContent: open ? "center" : "center",
            borderRadius: "14px",
            display: "flex",
            alignItems: "center",
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.main",
            },
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
            }}
          >
            {open && (
              <Typography
                variant="subtitle1"
                sx={{
                  textAlign: "center",
                  width: "fit-content",
                  fontWeight: 600,
                }}
              >
                Add Product
              </Typography>
            )}

            <ListItemIcon
              sx={{
                mr: open ? 2 : "auto",
                justifyContent: "center",
                marginRight: "0px",
                width: "fit-content",
                minWidth: open ? "24px" : "56px",
              }}
            >
              <PlusIconSvg />
            </ListItemIcon>
          </Box>
        </ListItemButton>
      </ListItem>
    </List>
  );
};

export default BottomListItems;

import { AlertTriangleIconSvg } from "../../assets/icons/AlertTriangleIconSvg";
import { DashboardIconSvg } from "../../assets/icons/DashboardIconSvg";
import { LayersIconSvg } from "../../assets/icons/LayersIconSvg";
import { MessageIconSvg } from "../../assets/icons/MessageIconSvg";

export const mainNavigation: any = [
  {
    text: "Dashboard",
    module: "DASHBOARD",
    icon: <DashboardIconSvg color="rgba(255, 255, 255, 0.5)" />,
    iconSelected: <DashboardIconSvg color="white" />,
    to: "/",
  },
  {
    text: "Products",
    module: "PRODUCTS",
    icon: <LayersIconSvg color="rgba(255, 255, 255, 0.5)" />,
    iconSelected: <LayersIconSvg color="white" />,
    to: "/product",
  },
  {
    text: "Reports",
    module: "REPORTS",
    icon: <AlertTriangleIconSvg color="rgba(255, 255, 255, 0.5)" />,
    iconSelected: <AlertTriangleIconSvg color="white" />,
    to: "/reports",
  },
  {
    text: "Feedbacks",
    module: "FEEDBACKS",
    icon: <MessageIconSvg color="rgba(255, 255, 255, 0.5)" />,
    iconSelected: <MessageIconSvg color="white" />,
    to: "/feedbacks",
  },
];

import React from "react";
import { IconColor } from "./interface/ColorInterface";

const CrossBig: React.FC<IconColor> = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "28"}
      height={height || "28"}
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M7 21L21 7"
        stroke={color || "black"}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21 21L7 7"
        stroke={color || "black"}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    // <path d="M13.4,12l7.3,7.3l-1.4,1.4L12,13.4l-7.3,7.3l-1.4-1.4l7.3-7.3L3.3,4.7l1.4-1.4l7.3,7.3l7.3-7.3l1.4,1.4L13.4,12z" />
  );
};

// export const CrossBig: React.FC<SvgIconProps> = ({ ...props }) => {
//   return (
//     <SvgIcon {...props}>
//       <CrossBigSvg color={props?.color} />
//     </SvgIcon>
//   );
// };

export default CrossBig;

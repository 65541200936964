import axios from "axios";
import Cookies from "js-cookie";
import { HOST_NAME } from "../utils/constants";

export const createComponent = async (data: any, files: File[]) => {
  return new Promise((resolve, reject) => {
    // Create a FormData object to hold the data and files
    const formData = new FormData();

    files.forEach((file, index) => {
      formData.append("files", file);
    });

    Object.keys(data).forEach((key) => {
      // If the value is an array (e.g., tags), handle it appropriately
      if (Array.isArray(data[key])) {
        data[key].forEach((item: string, index: number) => {
          formData.append(`${key}[${index}]`, item);
        });
      } else {
        formData.append(key, data[key]);
      }
    });
    // Configure the request
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/component`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
        "Content-Type": "multipart/form-data", // Important for file uploads
      },
      data: formData,
    };

    // Send the request
    axios
      .request(config)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllComponents = (filters?: any) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/component?search=${
        filters?.searchBy || ""
      }&searchByTitle=${filters?.searchByTitle || ""}&page=${
        filters?.page || 1
      }&pageSize=${filters?.pageSize || 25}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getComponentById = (id: string) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/component/${id}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteComponent = (id: string) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/component/${id}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const deleteDocuments = (data: any) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/documents`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
      data: { documentIds: data },
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateComponent = (id: string, data: any, files?: any) => {
  return new Promise((resolve, reject) => {
    // Create a FormData object to hold the data and files
    const formData = new FormData();

    files?.forEach((file: any, index: any) => {
      formData.append("files", file);
    });

    Object.keys(data).forEach((key) => {
      // If the value is an array (e.g., tags), handle it appropriately
      if (Array.isArray(data[key])) {
        data[key].forEach((item: string, index: number) => {
          formData.append(`${key}[${index}]`, item);
        });
      } else {
        formData.append(key, data[key]);
      }
    });
    const config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/component/${id}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
      data: formData,
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateComponentThumbnail = (data: any) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/component-thumbnail`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateMultipleComponents = (data: any) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/multiple-component`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllComponentsFeedback = (filters?: any) => {
  return new Promise<any>((resolve, reject) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/feedbacked-components?search=${
        filters?.searchBy || ""
      }&page=${filters?.page || 1}&pageSize=${filters?.pageSize || 25}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const getAllFeedbacks = (filters?: any) => {
  return new Promise((resolve, reject) => {
    const page =
      filters?.page !== undefined &&
      filters?.page !== null &&
      filters?.page !== ""
        ? filters?.page
        : "";
    const pageSize =
      filters?.pageSize !== undefined &&
      filters?.pageSize !== null &&
      filters?.pageSize !== ""
        ? filters?.pageSize
        : "";
    const componentId =
      filters?.componentId !== undefined &&
      filters?.componentId !== null &&
      filters?.componentId !== ""
        ? filters?.componentId
        : "";

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/feedback?componentId=${componentId}&page=${page}&pageSize=${pageSize}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateFeedbackById = (data: any, id: string) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/feedback/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const getAllComponentsReport = (filters?: any) => {
  return new Promise<any>((resolve, reject) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/reported-components?search=${
        filters?.searchBy || ""
      }&page=${filters?.page || 1}&pageSize=${filters?.pageSize || 25}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const getAllReports = (filters?: any) => {
  return new Promise((resolve, reject) => {
    const page =
      filters?.page !== undefined &&
      filters?.page !== null &&
      filters?.page !== ""
        ? filters?.page
        : "";
    const pageSize =
      filters?.pageSize !== undefined &&
      filters?.pageSize !== null &&
      filters?.pageSize !== ""
        ? filters?.pageSize
        : "";
    const componentId =
      filters?.componentId !== undefined &&
      filters?.componentId !== null &&
      filters?.componentId !== ""
        ? filters?.componentId
        : "";

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/problem?componentId=${componentId}&page=${page}&pageSize=${pageSize}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const updateReportById = (data: any, id: string) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/problem/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

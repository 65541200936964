import React, { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { RotateElement } from "../../shared/components/Rotate";
import { ChevronRight } from "@mui/icons-material";

interface SubItem {
  id: number;
  text: string;
  module: string;
  icon: React.ReactNode;
  to: string;
  subNavigations?: SubItem[];
}

interface SubItemListProps {
  open?: boolean;
  menuList?: SubItem[];
  setActiveNavigation: (item: SubItem) => void;
  activeNavigation: SubItem | null;
}

const SubItemList: React.FC<SubItemListProps> = ({
  open,
  menuList,
  setActiveNavigation,
  activeNavigation,
}) => {
  const [collapsedItems, setCollapsedItems] = useState<number[]>([]);

  // Expand the list containing the activeNavigation
  useEffect(() => {
    const findParentIndex = (items: SubItem[], parentIndex: number) => {
      items?.forEach((subItem, index) => {
        if (
          subItem?.subNavigations &&
          subItem?.subNavigations.some(
            (item) =>
              item?.module === activeNavigation?.module &&
              item?.text === activeNavigation?.text
          )
        ) {
          const itemIndex = parentIndex * 10 + index;
          setCollapsedItems((prevState) => [...prevState, itemIndex]);
        } else {
          if (subItem?.subNavigations) {
            findParentIndex(subItem?.subNavigations, index);
          }
        }
      });
    };

    if (activeNavigation) {
      findParentIndex(menuList || [], -1);
    }
  }, [activeNavigation, menuList]);

  const toggleCollapse = (index: number) => {
    if (collapsedItems?.includes(index)) {
      setCollapsedItems((prevState) =>
        prevState?.filter((item) => item !== index)
      );
    } else {
      setCollapsedItems((prevState) => [...prevState, index]);
    }
  };

  const renderSubItems = (subItems: SubItem[], parentIndex: number) => {
    return (
      <List>
        {subItems
          ?.filter((a) => a !== null)
          ?.map((subItem, index) => {
            const itemIndex = parentIndex * 10 + index;
            const isLastSubItem =
              index ===
              subItems?.filter((item: any) => item !== null).length - 1;
            return (
              <React.Fragment key={subItem?.id}>
                <ListItem sx={{ width: "100%" }} disablePadding>
                  {open && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      marginLeft={parentIndex === -1 ? "16px" : "26px"}
                      position="relative"
                      alignItems="flex-start"
                      justifyContent="flex-start"
                    >
                      <Box
                        height={
                          subItem?.subNavigations &&
                          !isLastSubItem &&
                          collapsedItems?.includes(itemIndex)
                            ? `${
                                subItem?.subNavigations?.length > 0
                                  ? (subItem?.subNavigations?.length + 1.5) * 48
                                  : 1
                              }px`
                            : subItems?.indexOf(subItem) === 0
                            ? "41px"
                            : "48px"
                        }
                        borderLeft="2px solid #363A52"
                        top={
                          subItems?.indexOf(subItem) === 0 ? "-43px" : "-50px"
                        }
                        position="absolute"
                      />
                      <Box
                        height="24px"
                        borderBottom="2px solid #363A52"
                        sx={{
                          borderBottomLeftRadius: "5px",
                        }}
                        width="12px"
                        top="-23px"
                        left="0"
                        position="absolute"
                      />
                    </Box>
                  )}
                  {subItem?.subNavigations && (
                    <ListItemButton
                      onClick={() => toggleCollapse(itemIndex)}
                      disableRipple
                      sx={{
                        justifyContent: "flex-start",
                        "&:hover": {
                          bgcolor: "transparent",
                        },
                      }}
                    >
                      <ListItemText
                        sx={{ paddingLeft: "10px" }}
                        primary={
                          <Typography
                            variant="body2"
                            sx={{
                              color:
                                activeNavigation?.text === subItem?.text &&
                                activeNavigation?.module === subItem?.module
                                  ? "text.primary"
                                  : "#87888C",
                              fontWeight: 500,
                            }}
                          >
                            {subItem?.text}
                          </Typography>
                        }
                      />
                      <Box sx={{ marginRight: "-4px" }}>
                        {collapsedItems?.includes(itemIndex) ? (
                          <RotateElement rotate={"270deg"}>
                            <ChevronRight
                              sx={{
                                color: "#87888C",
                              }}
                            />
                          </RotateElement>
                        ) : (
                          <RotateElement rotate={"90deg"}>
                            <ChevronRight
                              sx={{
                                color: "#87888C",
                              }}
                            />
                          </RotateElement>
                        )}
                      </Box>
                    </ListItemButton>
                  )}
                  {!subItem?.subNavigations && (
                    <Link
                      style={{
                        width: "100%",
                        marginRight: "8px",
                        backgroundColor: "#F8F8F8 !important",
                        height: "100%",
                      }}
                      to={subItem?.to}
                    >
                      <ListItemButton
                        onClick={() => setActiveNavigation(subItem)}
                        disableRipple
                        sx={{
                          minHeight: 48,
                          width: "100%",
                          justifyContent: open ? "initial" : "center",
                          "&.MuiButtonBase-root:hover": {
                            bgcolor: "transparent",
                            padding: "0px",
                          },
                          "&.MuiButtonBase-root": {
                            bgcolor: "transparent",
                            padding: "0px",
                          },
                        }}
                      >
                        <ListItemText
                          primary={
                            <Typography
                              variant="body2"
                              sx={{
                                color:
                                  activeNavigation?.text === subItem?.text &&
                                  activeNavigation?.module === subItem?.module
                                    ? "text.primary"
                                    : "#87888C",
                                fontWeight: 500,
                              }}
                            >
                              {subItem?.text}
                            </Typography>
                          }
                          sx={{
                            "&.MuiListItemText-primary": {
                              width: "100% !important",
                            },
                            "&.MuiListItemText-root": {
                              margin: 0,
                              marginLeft: "12px",
                              paddingLeft: "14px",
                              fontWeight: 500,
                              width: "100% !important",
                            },
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            opacity: open ? 1 : 0,
                            width: "100% !important",
                            textAlign: "center",
                            borderRadius: "10px",
                            backgroundColor:
                              activeNavigation?.text === subItem?.text &&
                              activeNavigation?.module === subItem?.module
                                ? "primary.main"
                                : "transparent",
                            position: "relative",
                            height: "48px",
                            fontWeight: 500,
                          }}
                        ></ListItemText>
                      </ListItemButton>
                    </Link>
                  )}
                </ListItem>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: "20px",
                  }}
                >
                  {collapsedItems?.includes(itemIndex) &&
                    subItem?.subNavigations &&
                    renderSubItems(subItem?.subNavigations, itemIndex)}
                </Box>
              </React.Fragment>
            );
          })}
      </List>
    );
  };

  return <>{menuList && renderSubItems(menuList, -1)}</>;
};

export default SubItemList;

import React, { useState } from "react";
import PopupDialog from "../../../../shared/components/PopupScreen/Dialog";
import { Box, Typography } from "@mui/material";
import TextAreaInput from "../../../../shared/components/TextAreaInput";
import TagInput from "../../../../shared/components/tags/Tags";
import TextInput from "../../../../shared/components/TextInput";
import { createStyles, makeStyles } from "@mui/styles";
import { ButtonPrimary } from "../../../../shared/components/button/Buttons";
import { updateMultipleComponents } from "../../../../services/component";
import { toastError, toastSuccess } from "../../../../utils/ToastMessage";

interface EditMultipleComponentsProps {
  selectedRows: Array<string>;
  setEditMultipleComponentsDialogueVisible: any;
  setActiveSelectedMenu: any;
  setProductList: any;
  setSelectedRows: any;
}

const EditMulipleComponentsDialogue = (props: EditMultipleComponentsProps) => {
  const styles = useStyles();
  const {
    selectedRows,
    setEditMultipleComponentsDialogueVisible,
    setActiveSelectedMenu,
    setProductList,
    setSelectedRows,
  } = props;

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState([]);
  const [blurb, setBlurb] = useState("");

  const handleSaveEditMultipleComponents = async () => {
    const data = {
      ids: selectedRows,
      title,
      description,
      blurb,
      tags,
    };

    await updateMultipleComponents(data)
      .then((res: any) => {
        toastSuccess("Success", "Successfully updated Multiple components");
        setEditMultipleComponentsDialogueVisible(false);
        setActiveSelectedMenu(false);
        // setProductList(res?.data);
        setSelectedRows([]);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        toastError("Error", "There is some error updating component.");
      });
  };

  return (
    <PopupDialog
      style={{
        width: "900px !important",
        maxHeight: "fi-content",
        height: "100%",
        overflow: "hidden !important",
        display: "flex",
        // gap: "36px",
        justifyContent: "center",
        backgroundColor: "#141414",
        background: "#141414",
        boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
      }}
      open={true}
      //   title="Edit"
      //   titleStyle={{
      //     color: "white",
      //   }}
      onClose={() => {
        props?.setEditMultipleComponentsDialogueVisible(false);
      }}
      children={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            bgcolor: "#141414",
            borderRadius: "14px",
            padding: "40px",
            gap: "24px",
          }}
        >
          {/* Row1 */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "40px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                gap: "24px",
              }}
            >
              <Box sx={{ flex: 1 }} className={styles.inputContainer}>
                <TextInput
                  style={{ flex: 1 }}
                  label="Title"
                  lableStyles={{ fontSize: "16px !important" }}
                  placeholder="Enter the title"
                  type="text"
                  value={title}
                  onChange={(e: any) => {
                    setTitle(e.target.value);
                  }}
                />

                <Typography
                  className="err_field"
                  id="createProductTitlebNotExist"
                  color="red"
                  variant="body2"
                ></Typography>
              </Box>
              <Box sx={{ flex: 1 }} className={styles.inputContainer}>
                <TextInput
                  style={{ flex: 1 }}
                  label="Blurb"
                  lableStyles={{ fontSize: "16px !important" }}
                  placeholder="Short product description"
                  type="text"
                  value={blurb}
                  onChange={(e: any) => {
                    setBlurb(e.target.value);
                  }}
                />

                <Typography
                  className="err_field"
                  id="createProductBlurbNotExist"
                  color="red"
                  variant="body2"
                ></Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                gap: "24px",
              }}
            >
              <Box>
                <TagInput
                  textinputstyles={{
                    maxWidth: "430px",
                    minWidth: "100%",
                  }}
                  value={tags || []}
                  onChange={(newTags: any) => {
                    setTags(newTags);
                  }}
                  label="Tags (Maximum 5)"
                />
                <Typography
                  className="err_field"
                  id="createProductTagsNotExist"
                  color="red"
                  variant="body2"
                ></Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ flex: 1 }} className={styles.inputContainer}>
            <TextAreaInput
              style={{
                flex: 1,
                height: "172px",
              }}
              label="Description"
              lableStyles={{ fontSize: "16px !important" }}
              placeholder="Long product description"
              type="text"
              value={description}
              onChange={(e: any) => {
                setDescription(e.target.value);
              }}
            />

            <Typography
              className="err_field"
              id="createProductDescNotExist"
              color="red"
              variant="body2"
            ></Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ButtonPrimary
              sx={{
                height: "45px !important",
                border: "14px !important",
                width: "112px",
              }}
              onClick={() => {
                handleSaveEditMultipleComponents();
              }}
              isLoading={false}
              LabelStyle={{ fontSize: "18px !important" }}
              label="Save"
            />
          </Box>
        </Box>
      }
    />
  );
};

export default EditMulipleComponentsDialogue;

const useStyles = makeStyles((theme) =>
  createStyles({
    dropdown: {
      width: "100%",
      minWidth: "200px",
    },
    placeholder: {
      color: "#687588",
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "Inter Tight",
    },
    inputContainer: {
      flex: 1,
    },
    field: {
      // width: "100%",
      // maxWidth: "100%",
    },
  })
);

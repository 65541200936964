import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";
import { CircularProgress } from "@mui/material";

interface ProtectedRoutesProps {}

const Protected: React.FC<ProtectedRoutesProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [token, setToken] = useState("");
  useEffect(() => {
    setLoading(true);
    const userToken = Cookies.get("jstoken");
    if (userToken) {
      setToken(userToken);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {}, [token]);

  if (loading) {
    return (
      <CircularProgress
        size={24}
        sx={{
          color: "white",
          marginRight: "12px",
        }}
      />
    );
  }
  return token ? <Outlet /> : <Navigate to="/login" />;
};

export default Protected;
